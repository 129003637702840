import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchRoles(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/role/list`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchRole(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/role`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addRole(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/role", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateRole(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/role/setRolePermission", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/staff/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/staff/?id=${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getAccessRights(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/staff/getAccessRights", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/staff", userData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchUserOption(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/options?user=1")
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    setUserRole(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/role/setUserRole", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    setUserDirectPermission(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/permission/setUserDirectPermission", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
